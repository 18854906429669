import React from 'react';
import { Table } from 'antd';
import { useHistory } from 'react-router-dom';
import { DATE_PICKER_YEAR_MONTH_DATE } from '../../../constants';
import moment from 'moment';
import { ORDERS_PODETAILS } from '../../../routingUrl';

const ASNSentTable = ({ data, activeEvent }) => {
    const history = useHistory();

    // For navigating to inner pages based on status
    const navToOrdersActivity = (item) => { history.push({ pathname: ORDERS_PODETAILS, state: { PK: item.PK, SK: activeEvent + 'Item#' + item.InvoiceID, type: activeEvent, data: item } }) }

    const tableData = [];
    data && data.map((item, i) => {
        var obj = {
            key: i,
            invoiceId: <p onClick={() => navToOrdersActivity(item)}> <span className="cursorpointer">{item.InvoiceID}</span></p>,
            deliveryeDate: <p onClick={() => navToOrdersActivity(item)}> <span className="cursorpointer">{moment(item.GSI2).format(DATE_PICKER_YEAR_MONTH_DATE)}</span></p>,
            invoiceDate: <p onClick={() => navToOrdersActivity(item)}> <span className="cursorpointer">{moment(item.GSI1SK).format(DATE_PICKER_YEAR_MONTH_DATE)}</span></p>,
            status: <p onClick={() => navToOrdersActivity(item)}> <span className="cursorpointer capitalizeAllCaps">{item.Status.toLowerCase()}</span></p>,
            ASNStatusDescription: <p onClick={() => navToOrdersActivity(item)}> <span className="cursorpointer capitalizeAllCaps">{item?.Message?.toLowerCase()}</span></p>,
        }
        tableData.push(obj);
    })

    const columns = [{
        title: 'Invoice ID',
        dataIndex: 'invoiceId',
        key: 'invoiceId'
    }, {
        title: 'Invoice Date',
        dataIndex: 'invoiceDate',
        key: 'invoiceDate'
    }, {
        title: 'Delivery Date',
        dataIndex: 'deliveryeDate',
        key: 'deliveryeDate'
    }, {
        title: 'Status',
        dataIndex: 'status',
        key: 'status'
    }, {
        title: 'ASN Status Description',
        dataIndex: 'ASNStatusDescription',
        key: 'ASNStatusDescription'
    }];

    return (<Table dataSource={tableData} columns={columns} className="primary-color-table" pagination={false} scroll={{ x: 500 }} />)
}

export default ASNSentTable;