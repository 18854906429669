export const ORDERS = "orders";

export const HOME = '/';
export const DASHBOARD = "/dashboard";
export const LOGIN = "/login";

// orders routing 
export const ORDERS_DASHBOARD = "/orders";
export const ORDERS_ACTIVITY = "/orders/details";
export const PAGE_BY_SEARCH = "/orders/:id";
export const ORDERS_PODATA = "/orders/po-data";
export const ORDERS_PODETAILS = "/orders/po-details";

// Companies Routing
export const COMAPNIES_DASHBOARD = "/companies";
export const COMAPNIES_CREATE = "/companies/create";

// Users Routing
export const USERS_DASHBOARD = "/users";
export const USERS_CREATE = "/users/create";
export const USERS_EDIT = "/users/edit";
export const SO_MAIL_USERS_CREATE = "/users/so-mail-create";
export const ERROR_MAIL_USERS_CREATE = "/users/error-mail-create";

// master data
export const MASTER_DATA_DASHBOARD = "/master-data";
export const MASTER_DATA_CREATE = "/master-data/create";
export const MASTER_DATA_EDIT = "/master-data/edit";

// Product Data
export const PRODUCT_DATA_DASHBOARD = "/product-data";
export const PRODUCT_DATA_CREATE = "/product-data/create";
export const PRODUCT_DATA_EDIT = "/product-data/edit";

// Customer Data
export const CUSTOMER_DATA_DASHBOARD = "/customer-data";
export const CUSTOMER_DATA_CREATE = "/customer-data/create";




