import React, { useState, useEffect, useMemo } from 'react';
import { Layout, Avatar, Dropdown, Col, Row, Tooltip } from 'antd';
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Scrollbars } from 'react-custom-scrollbars';
import { MenuOutlined } from '@ant-design/icons';
import RespLogo from '../../utils/images/tataLogoSmall.svg';
import DeskLogo from '../../utils/images/tataLogoLarge.svg';
import './index.scss';
import { useHistory } from 'react-router-dom';
import LeftMenu from './layout/leftMenu';
import { DownOutlined } from '@ant-design/icons';
import ProfileMenus from './layout/proFileMenu';
import DropDownSelect from '../../utils/components/customeInputFields/dropDownSelect';
import GlobalSearch from './globalSerach';
import { DASHBOARD, ORDERS_ACTIVITY, ORDERS_DASHBOARD, ORDERS_PODATA, ORDERS_PODETAILS } from '../../utils/routingUrl';
import { getCompanyNamesList } from '../../utils/functions';
import MobileMenu from './layout/mobileMenu';
import { SALES_PERSON_KEY, SUPER_ADMIN_KEY } from '../../utils/constants';
import { USER_MANUAL } from '../../utils/constants';
import { Storage } from "aws-amplify";

const { Header, Content, Sider } = Layout;

function Dashboard({ children, onCompanyNameChange }) {
  const history = useHistory();
  const companyNameSelected = sessionStorage.getItem('companyName');
  const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  const companiesList = JSON.parse(sessionStorage.getItem('companiesList'));
  const data = sessionStorage.getItem("email");
  const splitData = data.split("@");
  const [companyName, setCompanyName] = useState("");

  useEffect(() => { setCompanyName(companyNameSelected ? companyNameSelected : ''); }, [companyNameSelected]);

  const hadleSelect = (info, e) => {
    setCompanyName(e);
    companiesList && companiesList.map(item => {
      if (e == item.CompanyName) {
        sessionStorage.setItem('companySK', item.SK);
        onCompanyNameChange(item.SK);

        if (userDetails.GSI1PK != SUPER_ADMIN_KEY) {
          let obj = {
            CompanyName: item.CompanyName,
            GSI1PK: item.GSI1PK,
            GSI1SK: item.GSI1SK,
            GSI2: item.GSI2,
            IsActive: item.IsActive,
            PK: item.PK,
            SK: item.SK
          }
          obj.companiesList = userDetails.companiesList;
          if (window.location.pathname.includes('/user') && item.GSI1PK === SALES_PERSON_KEY) {
            history.push({ pathname: ORDERS_DASHBOARD });
          }
          sessionStorage.setItem('userDetails', JSON.stringify(obj));
        }

      }
    })
    sessionStorage.setItem('companyName', e);
  }

  const handleManualDownload = async () => {
    let response = await Storage.get(USER_MANUAL, {
      download: true,
    });
    if (response) {
      console.log(response);
      await downloadBlob(response.Body, USER_MANUAL);
    }
  };
  async function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    let file = filename.split("/")[0];
    a.download = file || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  const companyNameOptions = useMemo(() => getCompanyNamesList(companiesList), [companiesList])
  const disabledCondition = window.location.pathname === ORDERS_ACTIVITY ||
    window.location.pathname === ORDERS_PODATA || window.location.pathname === ORDERS_PODETAILS;

  return (
    <Layout style={{ minHeight: '100vh' }} className="layout-main">
      <Layout>
        <Header className="topHeader">
          <Col lg={{ span: '2' }} xs={{ span: '4' }} className="hamburgerBGCol">
            <div className="hamburgerBG">
              <div className="mobileLogo">  <MobileMenu /> </div>
              <div className="DesktopLogo">   <MenuOutlined /> </div>
            </div>
          </Col>
          <Col lg={{ span: '5' }} xs={{ span: '4' }} className="verticalCenter">
            <img src={DeskLogo} alt="Tata logo" className="nonResp" />
            <img src={RespLogo} alt="Tata logo" className="inResp" />
          </Col>
          <Col lg={{ span: '11' }} xs={{ span: '0' }} className="navTopBottom1em paddingRight">
            {window.location.pathname.includes(ORDERS_DASHBOARD) ? <GlobalSearch /> : null}
          </Col>

          <Col lg={{ span: '6' }} xs={{ span: '16' }} className="MenuBg-main">
            <div className="MenuBg">
              <ul className="custom-list-inline">
                {window.location.pathname !== DASHBOARD ? <li className="companySelectStyles">
                  <DropDownSelect value={companyName || undefined} name="companyName" placeholder="Select a Company" onChange={(e) => { hadleSelect("companyName", e) }}
                    selectStyles="selectStyles borderNone width200" options={companyNameOptions} disabled={disabledCondition} />
                </li> : null}
                <li className="companySelectStyles hideInResp">
                  <Dropdown overlay={<ProfileMenus />} trigger={['click']}>
                    <p className="profileTitle">
                      <span className="profileTitleStyles">{splitData ? splitData[0] : "TT"}</span>
                      <DownOutlined className="ProfileDropdown" />
                    </p>
                  </Dropdown>
                </li>
                <li className="paddingLeft20">
                  <div className="profileStyles">
                    <Dropdown overlay={<ProfileMenus />} trigger={['click']}>
                      <Avatar size={40} className="ProfileBgColor">
                        <Avatar className="ProfileBgColor" src=''>{data ? data.charAt(0) : 'TT'}</Avatar>
                      </Avatar>
                    </Dropdown>
                  </div>
                </li>
                <li className="paddingLeft20">
                  <div className="profileStyles">
                    <Tooltip title="Download User Manual">
                      <QuestionCircleOutlined type="button" className="user-manual" onClick={() => { handleManualDownload() }} />
                    </Tooltip>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
        </Header>
        <Layout>
          <Sider className="leftMenu"> <LeftMenu /> </Sider>
          <Content>
            <Scrollbars className="main-body">
              {window.location.pathname.includes(ORDERS_DASHBOARD) ? <Row className="SubHeader">
                <Col lg={{ span: '0' }} xs={{ span: '24' }} className="navTopBottom1em respFexCenter">  <GlobalSearch />   </Col>
              </Row> : null}
              <div className="container"> {children}</div>
            </Scrollbars>
          </Content>
          <div className="footerview" id="footerview">
            {/* <Footer className="layoutFooter"> <p> BB Fitness Web © {new Date().getFullYear()} </p> </Footer> */}
          </div>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default Dashboard;