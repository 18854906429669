/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateEDIGatewayTable = /* GraphQL */ `
  mutation UpdateEDIGatewayTable($input: UpdateEDIGatewayTableInput!) {
    updateEDIGatewayTable(input: $input) {
      PK
      SK
      GSI1PK
      GSI1SK
      GSI2
      Type
      Status
      MRP
    }
  }
`;
export const addUser = /* GraphQL */ `
  mutation AddUser(
    $PK: String!
    $SK: String!
    $CompanyName: String!
    $GSI2: String!
    $GSI1PK: String!
    $GSI1SK: String!
    $IsActive: Boolean!
    $Type: String!
  ) {
    addUser(
      PK: $PK
      SK: $SK
      CompanyName: $CompanyName
      GSI2: $GSI2
      GSI1PK: $GSI1PK
      GSI1SK: $GSI1SK
      IsActive: $IsActive
      Type: $Type
    ) {
      PK
      SK
      GSI1PK
      GSI1SK
      GSI2
      CompanyName
      IsActive
      Type
    }
  }
`;
export const addCustomer = /* GraphQL */ `
  mutation AddCustomer(
    $PK: String!
    $SK: String!
    $CompanyName: String!
    $IsActive: Boolean!
    $Type: String!
  ) {
    addCustomer(
      PK: $PK
      SK: $SK
      CompanyName: $CompanyName
      IsActive: $IsActive
      Type: $Type
    ) {
      PK
      SK
      CompanyName
      Type
      IsActive
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $PK: String!
    $SK: String!
    $GSI2: String!
    $GSI1PK: String!
    $GSI1SK: String!
    $IsActive: Boolean!
  ) {
    updateUser(
      PK: $PK
      SK: $SK
      GSI2: $GSI2
      GSI1PK: $GSI1PK
      GSI1SK: $GSI1SK
      IsActive: $IsActive
    ) {
      PK
      SK
      GSI1PK
      GSI1SK
      GSI2
      CompanyName
      IsActive
      Type
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($PK: String!, $SK: String!) {
    deleteUser(PK: $PK, SK: $SK) {
      PK
      SK
      GSI1PK
      GSI1SK
      GSI2
      CompanyName
      IsActive
      Type
    }
  }
`;
export const sendEmail = /* GraphQL */ `
  mutation SendEmail(
    $From: String!
    $ToList: [String]!
    $CompanyCode: String
    $UserType: String
    $URL: String
  ) {
    sendEmail(
      From: $From
      ToList: $ToList
      CompanyCode: $CompanyCode
      UserType: $UserType
      URL: $URL
    )
  }
`;
export const updateCompanies = /* GraphQL */ `
  mutation UpdateCompanies(
    $PK: String!
    $Type: String!
    $itemIds: [CompanyStatus]
  ) {
    updateCompanies(PK: $PK, Type: $Type, itemIds: $itemIds) {
      items {
        PK
        SK
        CompanyName
        Type
        IsActive
      }
      nextToken
    }
  }
`;
export const mapProduct = /* GraphQL */ `
  mutation MapProduct(
    $PK: String!
    $SK: String!
    $GSI1PK: String!
    $GSI1SK: String!
    $Description: String
    $IsActive: Boolean!
  ) {
    mapProduct(
      PK: $PK
      SK: $SK
      GSI1PK: $GSI1PK
      GSI1SK: $GSI1SK
      Description: $Description
      IsActive: $IsActive
    ) {
      PK
      SK
      GSI1PK
      GSI1SK
      Description
      IsActive
      Type
    }
  }
`;
export const productMapping = /* GraphQL */ `
  mutation ProductMapping(
    $Type: String!
    $SearchID: String
    $UnMap: UnMap
    $Map: Map
    $Ids: Ids
    $ProductIds: ProductIds
    $DeleteMap: [DeleteMap]
    $MapProduct: MapProduct
  ) {
    productMapping(
      Type: $Type
      SearchID: $SearchID
      UnMap: $UnMap
      Map: $Map
      Ids: $Ids
      ProductIds: $ProductIds
      DeleteMap: $DeleteMap
      MapProduct: $MapProduct
    ) {
      statusCode
      body {
        SKUMapping {
          PK
          SK
          GSI1PK
          GSI1SK
          GSI2
          Type
          MRP
          HANAProductDescription
          S4HANAParentCode
          S4HANACode
          MainEAN
          CaseSize
          IsActive
        }
        CustomerProduct {
          PK
          SK
          GSI1PK
          GSI1SK
          GSI2
          Type
          IsActive
          ParentSKUDescription
          SystemSKUDescription
        }
        GTINList {
          PK
          SK
          GSI1PK
          GSI1SK
          GSI2
          Type
          MRP
          HANAProductDescription
          S4HANAParentCode
          S4HANACode
          MainEAN
          CaseSize
          IsActive
        }
        SKUParent {
          GSI1PK
          Division
          SystemSKUDescription
          GSI2
          PK
          SK
          Type
          ParentSKUDescription
        }
        CustomerCode
        DCCode
        CustProductID {
          ParentSKUCode
          SystemSKUCode
        }
      }
    }
  }
`;
export const customerMapping = /* GraphQL */ `
  mutation CustomerMapping($Type: String!) {
    customerMapping(Type: $Type)
  }
`;
export const sORetrigger = /* GraphQL */ `
  mutation SORetrigger($PK: String!) {
    SORetrigger(PK: $PK)
  }
`;
export const uploadMasterData = /* GraphQL */ `
  mutation UploadMasterData($customerId: String!, $Data: [MasterData]!) {
    uploadMasterData(customerId: $customerId, Data: $Data) {
      statusCode
      body
    }
  }
`;
