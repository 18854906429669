// datepicker formats
export const DATE_PICKER_YEAR_MONTH_DATE = "YYYY-MM-DD";
export const DATE_PICKER_MONTH_DATE_YEAR = "MMM-DD-YYYY";
export const DATE_PICKER_MONTH_DATE_YEAR_NUMBERS = "MM-DD-YYYY";
export const TIME_PICKER_HMS = "HH:mm:ss";
export const TIME_PICKER_MS = "mm:ss";
export const DATE_PICKER_TIME_ZONE = "YYYY-MM-DDTHH:mm:SS";
export const DATE_PICKER_TIME_ZONE_Z = "YYYY-MM-DDTHH:mm:ss[Z]";

// Orders Constants
export const OpenInvoice = "Open Invoice";
export const OpenPOs = "Open POs";
export const Invoice_ID = 'Open POs';
export const OpenPOs_ID = 'Open POs';
export const CLOSED_POS = 'Closed POs';
export const POs = "POs";
export const MASTER_DATA = 'Master Data';
export const SHIPPING_CODES = 'Shipping Codes'

// urls
export const LOCALHOST_URL = 'http://localhost:3000/';
export const STAGING_URL = 'https://staging.dphuhdyc53349.amplifyapp.com/';
// export const STAGING_URL = 'https://prod.dphuhdyc53349.amplifyapp.com/';

// components keys
export const COMPONENTFROM_CLOSEDPOS = 'CLOSEDPOS';
export const COMPONENTFROM_OPENPOS = 'OPENPOS';
export const COMPONENTFROM_OPENINVOICE = 'OPENINVOICE';
export const COMPONENTFROM_OPENIPOS_UNKNOWN_STATUS = 'OPENINVOICE_UNKNOWN_STATUS';
export const SEARCHBY_OPENINVOICE = 'INVOICE';

// numbers
export const NUMBER_ONE = '1';
export const NUMBER_TWO = '2';
export const NUMBER_THREE = '3';

// pagination buttons
export const PAGINATION_NEXT = 'next';
export const PAGINATION_PREVIOUS = 'previous';

// status related keys
export const STATUS_INVOICE_PENDING = 'Invoice Pending';
export const STATUS_PO_AMENDMENT = 'PO Amendment';
export const STATUS_INVOICE_AMENDMENT = 'Invoice Amendment';
export const STATUS_EAN_LOOPUP_FAILED = 'Article Look up Failed';
export const STATUS_XML_VALIDATION_FAIL = 'XML Validation Fail';
export const STATUS_CASELOT_AMENDMENT = 'Caselot Amendment';
export const STATUS_INSTANT_ACK_FAILED = 'Instant Acknowledgement Failed'

// keys from DB
export const KEY_ITEMAMEND = 'ItemAmend';
export const KEY_INVOICE = 'INVOICE';
export const KEY_ITEMEAN = 'ItemEAN';
export const KEY_XMLFAI = 'XMLFai';
export const KEY_ITEMCASELOT = 'ItemCaselotAmend';
export const KEY_INSTANTACK = 'InstantACKFailed';
export const KEY_AMENDMENT = 'AMENDMENT';
export const KEY_MRP_CHECK2 = 'MRP CHECK 2';
export const KEY_PARTIAL_INVOICE = 'PARTIAL_INVOICE';
export const PARTIAL_INVOICE = 'Partial Invoice';

// User Tabs
export const CUSTOMER_ADMIN = 'Customer Admin';
export const SO_MAIL_RECIPIENTS = 'SO Mail Recipients';
export const ERROR_MAIL_RECIPIENTS = 'Error Mail Recipients';
export const ADMIN = 'Admin';
export const SALES_PERSONS = 'Sales Persons';
export const SUB_ADMIN = 'Sub Admin';
export const SUPER_ADMIN = 'Super Admin';

// Company Names
export const AMAZON_COMPANY = 'Amazon';
export const RELIANCE_COMPANY = 'Reliance';
export const BIGBASKET = 'Big Basket';

// GSIPK oprions
export const SUPER_ADMIN_KEY = 'SuperAdmin';
export const CUST_ADMIN_KEY = 'CustAdmin';
export const SUB_ADMIN_KEY = 'SubAdmin';
export const SALES_PERSON_KEY = 'SalesPerson';
export const SO_MAIL_RECIPIENTS_KEY = 'CFA';   

export const ERROR_MAIL_RECIPIENTS_KEY = 'ErrorMailRecipients';
export const NOTIFICATION_KEY = 'Notification';

// true or "true"
export const TRUE_CONDITION = "true";

// yes or no keys
export const YES_KEY = 'Yes';
export const NO_KEY = 'No';

// keys
export const HASH_KEY = "#";
export const GTIN_KEY = 'GTIN';

//Usermanul
export const USER_MANUAL = 'UserManual.docx';