import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import RecordDocDark from "../../../utils/images/fileRecord.svg";
import RecordDocLite from "../../../utils/images/recordLite.svg";
import UsersIconDark from "../../../utils/images/userPrimary.svg";
import UsersIconLite from "../../../utils/images/UsersIconLite.svg";
import CompaniesDark from "../../../utils/images/companyDark.svg";
import CompaniesLite from "../../../utils/images/companieslite.svg";
import MasterDataDark from "../../../utils/images/masterDataDark.svg";
import MasterDataLite from "../../../utils/images/masterDataLite.svg";
import CustomerDarkIcon from "../../../utils/images/customerDarkIcon.svg";
import CustomerLiteIcon from "../../../utils/images/customerLiteIcon.svg";
import { useHistory } from "react-router-dom";
import {
  COMAPNIES_DASHBOARD,
  PRODUCT_DATA_DASHBOARD,
  ORDERS_DASHBOARD,
  USERS_DASHBOARD,
  CUSTOMER_DATA_DASHBOARD,
  MASTER_DATA_DASHBOARD,
} from "../../../utils/routingUrl";
import { SUPER_ADMIN_KEY, SALES_PERSON_KEY } from "../../../utils/constants";

const usersObj = {
  key: "2",
  name: "Users",
  activeImage: UsersIconDark,
  inActiveImage: UsersIconLite,
  location: USERS_DASHBOARD,
};

const productsObj = {
  key: "3",
  name: "Product Data",
  activeImage: MasterDataDark,
  inActiveImage: MasterDataLite,
  location: PRODUCT_DATA_DASHBOARD,
};

const customerObj = {
  key: "4",
  name: "Customer Data",
  activeImage: CustomerDarkIcon,
  inActiveImage: CustomerLiteIcon,
  location: CUSTOMER_DATA_DASHBOARD,
};

const masterObj = {
  key: "3",
  name: "Master Data",
  activeImage: MasterDataDark,
  inActiveImage: MasterDataLite,
  location: MASTER_DATA_DASHBOARD,
};

const ordersObj = {
  name: "Orders",
  activeImage: RecordDocDark,
  inActiveImage: RecordDocLite,
  location: ORDERS_DASHBOARD,
};

const companiesObj = {
  name: "Customers",
  activeImage: CompaniesDark,
  inActiveImage: CompaniesLite,
  location: COMAPNIES_DASHBOARD,
};

const LeftMenu = () => {
  const history = useHistory();
  const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
  const menuSelect = (key, item) => { if (key == item.key) history.push(item.location); };
  const [state, setState] = useState([]);

  useEffect(() => {
    if (userDetails) {
      var array;
      if (userDetails.GSI1PK == SALES_PERSON_KEY) {
        ordersObj.key = "1"; companiesObj.key = "4";
        array = [ordersObj, usersObj, masterObj, companiesObj];
      } else {
        ordersObj.key = "4"; companiesObj.key = "1";
        array = [companiesObj, usersObj, masterObj, ordersObj];
      }
      setState(array);
    }
  }, []);

  return (
    <div className="leftMenu-main">
      <Menu mode="vertical">
        {userDetails &&
          state.map((item, i) =>
            (item.name === "Customers" && userDetails.GSI1PK != SUPER_ADMIN_KEY) || (userDetails.GSI1PK == SALES_PERSON_KEY &&
              item.name === "Users") ? null : (
              <Menu.Item key={i + 1} className="left-menu-styles" onClick={() => menuSelect(i + 1, item)}  >
                <div className={window.location.pathname.includes(item.location) ? "svg-icon-active" : "svg-icon-normal"} >
                  <img src={window.location.pathname.includes(item.location) ? item.activeImage : item.inActiveImage} className="leftMenuIcon" />
                  <p>{item.name}</p>
                </div>
              </Menu.Item>)
          )}
      </Menu>
    </div>
  );
};

export default LeftMenu;
