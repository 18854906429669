import React, { useEffect } from 'react';
import { Row, Col, message } from 'antd';
import './index.scss'
import CustomButton from '../../../utils/components/buttons';
import ForBetterImage from '../../../utils/images/forBetter.svg';
import LogoLarge from '../../../utils/images/tataLogoLarge.svg';
import LogoSmall from '../../../utils/images/tataLogoSmall.svg';
import LoginBanner from '../../../utils/images/loginBanner.png';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { DASHBOARD } from '../../../utils/routingUrl';
import { useHistory } from 'react-router-dom';
import awsauth from "../../../awsauth.json";
import config from '../../../aws-exports';
// import { auth } from '../../../utils/functions';

function Login() {
    const history = useHistory();
    Amplify.configure(config);
    Auth.configure({ oauth: awsauth });

    useEffect(() => {
        Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case "signIn":
                    getUser().then(user => {
                        if (user) {
                            sessionStorage.setItem('isLogged', true);
                            sessionStorage.setItem('email', user.attributes.email);
                            history.push(DASHBOARD);
                            message.success("Logged in Successfully!")
                        }
                    });
                    break;
                case "signOut":
                    console.log("SSO-signOut", data);
                    break;
                case "customOAuthState":
                    console.log("SSO-customOAuthState", data);
            }
        });
    }, []);
    const onLoginURL = async () => {
        window.location.href ="https://pegasus.tataconsumer.com"
           }

    // const onLoginURL = async () => {  window.open("https://mtedi-staging.auth.ap-south-1.amazoncognito.com/oauth2/authorize?identity_provider=DevModernTradeEDIO365Provider&redirect_uri=https://staging.dphuhdyc53349.amplifyapp.com/&response_type=TOKEN&client_id=23b4nlnupfb9m5j2qcns6g3reu&scope=aws.cognito.signin.user.admin+email+openid")  }

    const getUser = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            return user;
        } catch {
            return console.log('Not signed in');
        }
    }

    const onLoginSSO = async () => {
        Auth.federatedSignIn({ provider: process.env.REACT_APP_FEDERATED_PROVIDER || 'ProdModernTradeEDIO365Provider' })
    }

    return (
        <div className="tcpl-loginpage-main">
            <Row className="flexInresp">
                <Col lg={{ span: 15 }} xs={{ span: 24 }} className="bannerBlock">
                    <img src={LoginBanner} alt="login banner" />
                </Col>
                <Col lg={{ span: 9 }} xs={{ span: 24 }} className="loginSec">
                    <div className="floatRight respHide">
                        <img src={ForBetterImage} alt="For Better Image" />
                    </div>
                    <div className="textCenter paddingSame">
                        <h1>LOGIN</h1>
                        <img src={LogoLarge} alt='TATA FULL LOGO' />
                        <div className='login-div'>
                             <div>
                             <h2>For PO's processed till 04<sup>th</sup> March</h2>
                             <CustomButton buttonLabel="Login" className="loginSSO" handleSubmit={onLoginSSO} />
                             </div>
                         <div>
                             <h2>For PO's processed after 04<sup>th</sup> March</h2>
                             <CustomButton buttonLabel="Login" className="loginSSO-pegasus" handleSubmit={onLoginURL}/>
                         </div>
	                         
                         </div>
                    </div>
                    <div className="floatRight"> <img src={LogoSmall} alt="For Better Image" /> </div>
                </Col>
            </Row>
        </div>
    )
}

export default Login;