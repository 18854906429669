import React, { useCallback, useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { useHistory } from 'react-router-dom';
import { getItems } from '../../../functions';
import DefaultSorting from '../../../images/defaultSort.svg';
import AsendingSorting from '../../../images/asendingSort.svg';
import DecendingSorting from '../../../images/decendingSort.svg';
import { MASTER_DATA_EDIT } from '../../../routingUrl';
import './index.scss';
import { SALES_PERSON_KEY } from '../../../constants';
import ReactExport from "react-data-export";

const ASCENDING = 'ascending';
const DECENDING = 'decending';
const TCPLPSKU = 'TCPLPSKU';
const CustDC = 'CustDC';
const CustProdID = 'CustProdID';
const TCPLSSKU = 'TCPLSSKU';
const MasterTable = ({ data, activeSortOrder, activeElement, updateKeyValue, productArray, fromCount, toCount }) => {
    const history = useHistory();
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    const [tableData, setTableData] = useState([]);

    const[res, setres] = useState();
    const [flag, setFlag] = useState(false);

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;    

    useEffect(() => { 
        if (data) setTableRowData(data);
    }, [data, res])        

    const setTableRowData = (data) => {
        const tableData = [];        
        data && data.map((item, i) => {
            var obj = {                
                key: i,
                division: item.Division, 
                TCPLPSKU: getItems(item.PK, 0), 
                TCPLSSKU: getItems(item.PK, 1),
                GTIN: '-', 
                TCPLPlant: item.PlantCode, 
                TCPLCustomer: item.CustomerCode,
                separator: ' | ',
                CustProdID: getItems(item.GSI1SK, 1), 
                CustDC: getItems(item.GSI2, 1), 
                CustProdDesc: item.CustomerProductDescription,                
                ParentSKUDescription: item.ParentSKUDescription, 
                SystemSKUDescription: item.SystemSKUDescription, 
                Division: item.Division,
                VendorCode: item.VendorCode
            }
            tableData.push(obj);
        })
        setTableData(tableData)
    }

    const handleSorting = (name, info) => { updateKeyValue(name, info, productArray, fromCount, toCount) }

    const getSorterIcon = useCallback((info) => {
        return activeSortOrder == ASCENDING && info == activeElement ? <span onClick={() => handleSorting(info, DECENDING)}><img src={AsendingSorting} className="cursorpointer" /></span> :
            activeSortOrder == DECENDING && info == activeElement ? <span onClick={() => handleSorting(info, ASCENDING)}><img src={DecendingSorting} className="cursorpointer" /></span> :
                <span onClick={() => handleSorting(info, ASCENDING)} > <img src={DefaultSorting} className="cursorpointer" /> </span>
    }, [activeSortOrder, activeElement])

    const toEditProductMapping = (record) => {
        if (userDetails.GSI1PK !== SALES_PERSON_KEY) history.push({ pathname: MASTER_DATA_EDIT, state: { data: record } })
    }

    const columns = [    
    {
        title: 'Division',
        dataIndex: 'division',
        width: '8%'
    }, {
        title: <p className="margin0">TCPL Parent SKU {getSorterIcon(TCPLPSKU)} </p>,
        dataIndex: 'TCPLPSKU',
        width: '11%'
    }, {
        title: <p className="margin0">TCPL Sys SKU {getSorterIcon(TCPLSSKU)} </p>,
        dataIndex: 'TCPLSSKU',
        width: '12%'
    }, {
        title: 'TCPL Plant',
        dataIndex: 'TCPLPlant',
        width: '8%'
    }, {
        title: 'TCPL Customer',
        dataIndex: 'TCPLCustomer',
        width: '10%'
    }, {
        title: '|',
        dataIndex: 'separator',
        width: '1%'
    }, {
        title: <p className="margin0">Cust Prod ID  {getSorterIcon(CustProdID)}</p>,
        dataIndex: 'CustProdID',
        width: '10%',
        // className: 'masterTable_CustProdID masterTable_FixedRight',
    }, {
        title: <p className="margin0">Cust DC  {getSorterIcon(CustDC)}</p>,
        dataIndex: 'CustDC',
        width: '7%',
        // className: 'masterTable_CustDC masterTable_FixedRight',
    }, {
        title: 'Cust Prod Desc',
        dataIndex: 'CustProdDesc',
        width: '23%',
        // className: 'masterTable_CustProdDesc masterTable_FixedRight',
    },
    {
        title: 'Vendor Code',
        dataIndex: 'VendorCode',
        width: '23%',
        // className: 'masterTable_CustProdDesc masterTable_FixedRight',
    }, {
        title: <>    
                <ExcelFile filename={`Master_Data_Details_${new Date().getFullYear() + '_' + (new Date().getMonth() + 1) + '_' + new Date().getDate()}`} 
                element={<Button type='button' >Download</Button>}>
                    {res?.map((res, index) => 
                    <ExcelSheet key={index} data={res} name={`MASTER_DATA_Sheet_${index}`}>
                        <ExcelColumn label="Division" value={(col) => col.Division}/>
                        <ExcelColumn label="TCPL Parent SKU" value={(col) => col.TCPLPSKU}/>
                        <ExcelColumn label="TCPL Sys SKU" value={(col) => col.TCPLSSKU}/>
                        <ExcelColumn label="TCPL Plant" value={(col) => col.TCPLPlant}/>
                        <ExcelColumn label="TCPL Customer" value={(col) => col.TCPLCustomer}/>
                        <ExcelColumn label="Cust Prod ID" value={(col) => col.CustProdID}/>
                        <ExcelColumn label="CustDC" value={(col) => col.CustDC}/>
                        <ExcelColumn label="Cust Prod Desc" value={(col) => col.CustProdDesc}/>
                        <ExcelColumn label="Vendor Code" value={(col) => col.VendorCode}/>
                    </ExcelSheet>
                    )}
                </ExcelFile>
                </>,
        dataIndex: 'download',
        key: 'download',
        width: '5%'
    }];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          setres([selectedRows]);
        },
      };

    return (<Table 
        rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
         dataSource={tableData} columns={columns} pagination={{ position: ["bottomCenter"] }}
        onRow={(record, i) => { return { onClick: event => { toEditProductMapping(record) } } }} scroll={{ x: 1250 }}
        className={userDetails.GSI1PK !== SALES_PERSON_KEY ? 'primary-color-table masterDataMappingTable cursorTRPointer' : 'primary-color-table masterDataMappingTable'} />)
}

export default MasterTable;