/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://elaj42ee5zdanehnzpd4j732km.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-south-1:9f43d034-f3e8-4fca-8e23-b978910d6fca",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_8sO6Ugh6x",
    "aws_user_pools_web_client_id": "5nrhjlejcs5fg0cn3cf9t72c5i",
    "oauth": {
        "domain": "mtedi-prod.auth.ap-south-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "mtediprod140259-prod",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
