/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEDIGatewayTable = /* GraphQL */ `
  query GetEDIGatewayTable($PK: String!, $SK: String!) {
    getEDIGatewayTable(PK: $PK, SK: $SK) {
      PK
      SK
      GSI1PK
      GSI1SK
      GSI2
      Type
      Status
      MRP
    }
  }
`;
export const listEDIGatewayTables = /* GraphQL */ `
  query ListEDIGatewayTables(
    $filter: TableEDIGatewayTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEDIGatewayTables(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        PK
        SK
        GSI1PK
        GSI1SK
        GSI2
        Type
        Status
        MRP
      }
      nextToken
    }
  }
`;
export const queryEDIGatewayTablesByGSI2 = /* GraphQL */ `
  query QueryEDIGatewayTablesByGSI2(
    $GSI2: String!
    $first: Int
    $after: String
  ) {
    queryEDIGatewayTablesByGSI2(GSI2: $GSI2, first: $first, after: $after) {
      items {
        PK
        SK
        GSI1PK
        GSI1SK
        GSI2
        Type
        Status
        MRP
      }
      nextToken
    }
  }
`;
export const queryEDIGatewayTablesByGSI1 = /* GraphQL */ `
  query QueryEDIGatewayTablesByGSI1(
    $GSI1PK: String!
    $first: Int
    $after: String
  ) {
    queryEDIGatewayTablesByGSI1(GSI1PK: $GSI1PK, first: $first, after: $after) {
      items {
        PK
        SK
        GSI1PK
        GSI1SK
        GSI2
        Type
        Status
        MRP
      }
      nextToken
    }
  }
`;
export const queryEDIGatewayTablesByGSI1Range = /* GraphQL */ `
  query QueryEDIGatewayTablesByGSI1Range(
    $GSI1PK: String!
    $start: String
    $end: String
    $first: Int
    $after: String
  ) {
    queryEDIGatewayTablesByGSI1Range(
      GSI1PK: $GSI1PK
      start: $start
      end: $end
      first: $first
      after: $after
    ) {
      items {
        PK
        SK
        GSI1PK
        GSI1SK
        GSI2
        Type
        Status
        MRP
      }
      nextToken
    }
  }
`;
export const queryInvoiceItemsByInvoiceID = /* GraphQL */ `
  query QueryInvoiceItemsByInvoiceID(
    $PK: String!
    $SK: String
    $first: Int
    $after: String
  ) {
    queryInvoiceItemsByInvoiceID(
      PK: $PK
      SK: $SK
      first: $first
      after: $after
    ) {
      items {
        PK
        SK
        GSI1PK
        GSI1SK
        GSI2
        Type
        Status
        MRP
        SNo
        InvoiceQuantity
        EAN
        UpdatedMRP
        ParentSKUDescription
        SystemSKUDescription
        ParentSKUCode
        SystemSKUCode
        CustomerProductID
        DeliveryQuantity
        NoofCases
      }
      nextToken
    }
  }
`;
export const queryPOSKBeginWith = /* GraphQL */ `
  query QueryPOSKBeginWith(
    $PK: String!
    $SK: String
    $first: Int
    $after: String
  ) {
    queryPOSKBeginWith(PK: $PK, SK: $SK, first: $first, after: $after) {
      items {
        PK
        SK
        GSI1PK
        GSI1SK
        GSI2
        Type
        Status
        MRP
      }
      nextToken
    }
  }
`;
export const queryOpenInvoiceByDateRange = /* GraphQL */ `
  query QueryOpenInvoiceByDateRange(
    $GSI1PK: String!
    $start: String
    $end: String
    $first: Int
    $after: String
  ) {
    queryOpenInvoiceByDateRange(
      GSI1PK: $GSI1PK
      start: $start
      end: $end
      first: $first
      after: $after
    ) {
      items {
        PK
        SK
        GSI1PK
        GSI1SK
        GSI2
        Type
        Status
        AmendmentSet
        ASNDate
      }
      nextToken
    }
  }
`;
export const queryAmendmentItems = /* GraphQL */ `
  query QueryAmendmentItems(
    $PK: String!
    $SK: String
    $first: Int
    $after: String
    $itemIds: [String]
  ) {
    queryAmendmentItems(
      PK: $PK
      SK: $SK
      first: $first
      after: $after
      itemIds: $itemIds
    ) {
      items {
        PK
        SK
        GSI1SK
        Type
        Status
        MRP
        UpdatedMRP
        EAN
        ParentSKUDescription
        SystemSKUDescription
        ParentSKUCode
        SystemSKUCode
        CustomerProductID
      }
      nextToken
    }
  }
`;
export const queryOpenPOByDateRange = /* GraphQL */ `
  query QueryOpenPOByDateRange(
    $GSI1PK: String!
    $start: String
    $end: String
    $first: Int
    $after: String
  ) {
    queryOpenPOByDateRange(
      GSI1PK: $GSI1PK
      start: $start
      end: $end
      first: $first
      after: $after
    ) {
      items {
        PK
        SK
        GSI1PK
        GSI1SK
        GSI2
        Type
        Status
        StatusSet
        Stage
        RequestCount
        UniqueID
        SalesOrder
      }
      nextToken
    }
  }
`;
export const queryEANFailedItems = /* GraphQL */ `
  query QueryEANFailedItems(
    $PK: String!
    $SK: String
    $first: Int
    $after: String
    $itemIds: [String]
  ) {
    queryEANFailedItems(
      PK: $PK
      SK: $SK
      first: $first
      after: $after
      itemIds: $itemIds
    ) {
      items {
        PK
        SK
        GSI1SK
        Type
        Status
        EAN
        SiteCode
        Message
        CustomerProductID
      }
      nextToken
    }
  }
`;
export const queryInvoicePendingItems = /* GraphQL */ `
  query QueryInvoicePendingItems(
    $PK: String!
    $SK: String
    $first: Int
    $after: String
  ) {
    queryInvoicePendingItems(PK: $PK, SK: $SK, first: $first, after: $after) {
      items {
        PK
        SK
        GSI1SK
        GSI2
        Type
        InvoiceStatus
        InvoiceSet
        MRP
        EAN
        Plant
        TargetQty
        SalesUnit
        ParentSKUDescription
        SystemSKUDescription
        ParentSKUCode
        SystemSKUCode
        CustomerProductID
        SalesOrder
        Message
      }
      nextToken
    }
  }
`;
export const queryOpenInvoicePOCount = /* GraphQL */ `
  query QueryOpenInvoicePOCount($GSI1PK: String!, $first: Int, $after: String) {
    queryOpenInvoicePOCount(GSI1PK: $GSI1PK, first: $first, after: $after) {
      scannedCount
      AmendmentCount
      ASNCount
    }
  }
`;
export const queryLineItemsByPOID = /* GraphQL */ `
  query QueryLineItemsByPOID(
    $PK: String!
    $SK: String
    $first: Int
    $after: String
  ) {
    queryLineItemsByPOID(PK: $PK, SK: $SK, first: $first, after: $after) {
      items {
        PK
        SK
        GSI1SK
        GSI2
        Type
        InvoiceStatus
        InvoiceSet
        MRP
        EAN
        Plant
        TargetQty
        SalesUnit
        ParentSKUDescription
        SystemSKUDescription
        ParentSKUCode
        SystemSKUCode
        CustomerProductID
        SalesOrder
        Message
      }
      nextToken
    }
  }
`;
export const querySearchAnythingByID = /* GraphQL */ `
  query QuerySearchAnythingByID($GSI2: String!, $first: Int, $after: String) {
    querySearchAnythingByID(GSI2: $GSI2, first: $first, after: $after) {
      items {
        PK
        SK
        GSI1SK
        GSI2
        Type
      }
      nextToken
    }
  }
`;
export const queryClosedPOByDateRange = /* GraphQL */ `
  query QueryClosedPOByDateRange(
    $GSI1PK: String!
    $start: String
    $end: String
    $first: Int
    $after: String
  ) {
    queryClosedPOByDateRange(
      GSI1PK: $GSI1PK
      start: $start
      end: $end
      first: $first
      after: $after
    ) {
      items {
        PK
        SK
        GSI1PK
        GSI1SK
        GSI2
        Type
        Status
        StatusSet
        Stage
        RequestCount
        UniqueID
        SalesOrder
      }
      nextToken
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies($PK: String!, $first: Int, $after: String) {
    listCompanies(PK: $PK, first: $first, after: $after) {
      items {
        PK
        SK
        CompanyName
        Type
        IsActive
      }
      nextToken
    }
  }
`;
export const queryCompletePOEvents = /* GraphQL */ `
  query QueryCompletePOEvents(
    $PK: String!
    $SK: String!
    $Type: String!
    $itemIds: [String]
    $PassedItemsSK: String
  ) {
    queryCompletePOEvents(
      PK: $PK
      SK: $SK
      Type: $Type
      itemIds: $itemIds
      PassedItemsSK: $PassedItemsSK
    ) {
      statusCode
      body {
        POData {
          PK
          SK
          GSI1PK
          GSI1SK
          GSI2
          Type
          Status
          StatusSet
          Stage
          RequestCount
          UniqueID
          SalesOrder
        }
        POEvents {
          PK
          SK
          GSI1SK
          GSI2
          Type
          Status
          FailedRecordSet
          Message
          TotalItemsCount
          FailedItemsCount
          InvoiceID
        }
        PassedItems {
          PK
          SK
          GSI1SK
          GSI2
          Type
          InvoiceStatus
          InvoiceSet
          MRP
          EAN
          Plant
          TargetQty
          SalesUnit
          ParentSKUDescription
          SystemSKUDescription
          ParentSKUCode
          SystemSKUCode
          CustomerProductID
          SalesOrder
          Message
        }
        AmendmentItems {
          PK
          SK
          GSI1SK
          Type
          Status
          MRP
          UpdatedMRP
          EAN
          ParentSKUDescription
          SystemSKUDescription
          ParentSKUCode
          SystemSKUCode
          CustomerProductID
        }
        EANItems {
          PK
          SK
          GSI1SK
          Type
          Status
          EAN
          SiteCode
          Message
          CustomerProductID
        }
        CaseLotAmendmentItems {
          PK
          SK
          GSI1SK
          Type
          Status
          MRP
          UpdatedMRP
          EAN
          CaseLot
          CorrectCaseLot
          ParentSKUDescription
          SystemSKUDescription
          ParentSKUCode
          SystemSKUCode
          CustomerProductID
        }
        PassedInvoiceItems {
          PK
          SK
          GSI1PK
          GSI1SK
          GSI2
          Type
          Status
          MRP
          SNo
          InvoiceQuantity
          EAN
          UpdatedMRP
          ParentSKUDescription
          SystemSKUDescription
          ParentSKUCode
          SystemSKUCode
          CustomerProductID
          DeliveryQuantity
          NoofCases
        }
      }
    }
  }
`;
export const queryUsers = /* GraphQL */ `
  query QueryUsers(
    $GSI1PK: String!
    $itemIds: [String]!
    $first: Int
    $after: String
  ) {
    queryUsers(
      GSI1PK: $GSI1PK
      itemIds: $itemIds
      first: $first
      after: $after
    ) {
      items {
        PK
        SK
        GSI1PK
        GSI1SK
        GSI2
        CompanyName
        IsActive
        Type
      }
      nextToken
    }
  }
`;
export const queryUsersSOMail = /* GraphQL */ `
  query QueryUsersSOMail($PK: String!) {
    queryUsersSOMail(PK: $PK) {
      items {
        PK
        SK
        CustomerCode
        EmailID1
        EmailID2
        GSI1PK
        GSI1SK
        GSI2
        PlantCode
        StoreID
        Type
        VendorCode
        IsActive
      }
      nextToken
    }
  }
`;
export const querySiteCode = /* GraphQL */ `
  query QuerySiteCode($PK: String!, $SK: String!) {
    querySiteCode(PK: $PK, SK: $SK) {
      items {
        PK
        SiteCode
      }
      nextToken
    }
  }
`;
export const queryUserRoles = /* GraphQL */ `
  query QueryUserRoles($PK: String!) {
    queryUserRoles(PK: $PK) {
      items {
        PK
        SK
        GSI1PK
        GSI1SK
        GSI2
        CompanyName
        IsActive
        Type
      }
      nextToken
    }
  }
`;
export const queryProductMasterData = /* GraphQL */ `
  query QueryProductMasterData($CustomerID: String!, $Type: String!) {
    queryProductMasterData(CustomerID: $CustomerID, Type: $Type) {
      statusCode
      body {
        SKUMapping {
          PK
          SK
          GSI1PK
          GSI1SK
          GSI2
          Type
          MRP
          HANAProductDescription
          S4HANAParentCode
          S4HANACode
          MainEAN
          CaseSize
          IsActive
        }
        CustomerProduct {
          PK
          SK
          GSI1PK
          GSI1SK
          GSI2
          Type
          IsActive
          ParentSKUDescription
          SystemSKUDescription
        }
      }
    }
  }
`;
export const queryProduct = /* GraphQL */ `
  query QueryProduct($PK: String!, $SK: String!) {
    queryProduct(PK: $PK, SK: $SK) {
      items {
        PK
        SK
        GSI1PK
        GSI1SK
        GSI2
        S4HANAParentCode
        S4HANACode
        HANAProductDescription
        MainEAN
        CaseSize
        Description
        IsActive
        Type
      }
      nextToken
    }
  }
`;
export const queryAzureADUser = /* GraphQL */ `
  query QueryAzureADUser($Email: String) {
    queryAzureADUser(Email: $Email) {
      statusCode
      body
    }
  }
`;
export const queryCaseLotAmendItems = /* GraphQL */ `
  query QueryCaseLotAmendItems(
    $PK: String!
    $SK: String!
    $first: Int
    $after: String
    $itemIds: [String]!
  ) {
    queryCaseLotAmendItems(
      PK: $PK
      SK: $SK
      first: $first
      after: $after
      itemIds: $itemIds
    ) {
      items {
        PK
        SK
        GSI1SK
        Type
        Status
        MRP
        UpdatedMRP
        EAN
        CaseLot
        CorrectCaseLot
        ParentSKUDescription
        SystemSKUDescription
        ParentSKUCode
        SystemSKUCode
        CustomerProductID
      }
      nextToken
    }
  }
`;
export const queryMappedProduct = /* GraphQL */ `
  query QueryMappedProduct(
    $CustomerPayerID: String!
    $filter: ProductTableFilterInput
    $first: Int
    $after: String
  ) {
    queryMappedProduct(
      CustomerPayerID: $CustomerPayerID
      filter: $filter
      first: $first
      after: $after
    ) {
      items {
        PK
        SK
        GSI1PK
        GSI1SK
        GSI2
        CustomerCode
        PlantCode
        CustomerProductDescription
        ParentSKUDescription
        SystemSKUDescription
        VendorCode
        VendorName
        Type
        Division
      }
      nextToken
    }
  }
`;
export const listAzureUsers = /* GraphQL */ `
  query ListAzureUsers($EmailSearchKey: String) {
    listAzureUsers(EmailSearchKey: $EmailSearchKey)
  }
`;
