import React, { useState } from 'react';
import { Upload, Button, message, Modal, Spin } from 'antd';
import { UploadOutlined, CheckCircleFilled } from '@ant-design/icons';
import * as XLSX from "xlsx";
import './styles.scss';

const showMessage = (method, msg) => {
  message.destroy()
  message[method](msg)
}

const UploadVideo = ({ UploadedFile, file, fileName }) => {
  const [state, setState] = useState({ loading: false });
  const { loading } = state;

  const uploadVideo = async options => {
    const { file } = options;
    setState(state => ({ ...state, loading: true }));
    try {
      if (file) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          /* Parse data */
          const ab = e.target.result;
          const wb = XLSX.read(ab, { type: "binary" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false });
          var obj = [];
          data.map((item, i) => {
            if (i > 0) {
              // obj.push({
              //   Division: item[0],
              //   VendorCode: item[1],
              //   VendorName: item[2],
              //   DC: item[3],
              //   PlantCode: item[4],
              //   CustomerCode: item[5],
              //   CustomerProductID: item[8],
              //   CustomerProductDescription: item[10],
              //   ParentSKUCode: item[11],
              //   ParentSKUDescription: item[12],
              //   SystemSKUCode: item[13],
              //   SystemSKUDescription: item[14]
              // })
              obj.push({
                Division: item[7],
                VendorCode: item[12],
                VendorName: item[13],
                DC: item[3],
                PlantCode: item[11],
                CustomerCode: item[4],
                CustomerProductID: item[5],
                CustomerProductDescription: item[6],
                ParentSKUCode: item[0],
                ParentSKUDescription: item[9],
                SystemSKUCode: item[1],
                SystemSKUDescription: item[10]
              })
            }
          })
          setState(state => ({ ...state, loading: false }));
          UploadedFile(obj, file.name);
        };
        reader.readAsArrayBuffer(file);
      } else showMessage('error', 'Please select xlsx file')

    } catch (err) {
      showMessage('error', 'Something went wrong while uploading the file')
    }
  }

  return (
    <>
      <Upload maxCount={1} fileList={[]} customRequest={uploadVideo} accept=".xlsx" className='customUploadBtn'>
        <Button> Upload <UploadOutlined /> </Button>

        {loading ?
          <div className="textAlignCenter spinStyles paddingTop10">
            <p><Spin className='spinSize' /> </p>
          </div>
          : fileName ?
            <div className="paddingTop10">
              <span className="file-success-msg"> <CheckCircleFilled /> </span>  {fileName}
            </div> : null}
        <Modal visible={loading} className="loadingModal">
          <div className="textAlignCenter">
            {/* <p><Spin className='colorWhite' /> </p> */}
          </div>
        </Modal>
      </Upload>
    </>);
};
export default UploadVideo;

