import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col, message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import Dashboard from '../../../dashboard';
import CustomeButton from '../../../../utils/components/buttons'
import DropDownSelect from '../../../../utils/components/customeInputFields/dropDownSelect';
import CustomInput from '../../../../utils/components/customeInputFields/input';
import { MASTER_DATA_DASHBOARD, MASTER_DATA_EDIT } from '../../../../utils/routingUrl';
import { productMapping } from '../../../../graphql/mutations';
import API from '@aws-amplify/api';
import { getSystemSKUList } from '../../../../utils/functions';
import './index.scss';
import { SALES_PERSON_KEY } from '../../../../utils/constants';

const MAPPING = 'MAPPING';
const UN_MAPPING = 'UN_MAPPING';
const UPDATE_MAPPING = 'UPDATE_MAPPING';
const CREATE = 'create';
const UPDATE = 'update';
const UNMAP = 'unmap';

const CreateMasterData = (props) => {
    const history = useHistory();
    const location = useLocation();
    const [editBtn, setEditBtn] = useState(false);
    const [submitBtn, setSubmitBtn] = useState(true);
    const [companyName, setCompanyName] = useState(sessionStorage.getItem('companySK'));
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));

    const [state, setState] = useState({
        TCPLParentSKU: '',
        TCPLParentSKUDesc: '',
        TCPLSystemSKU: '',
        TCPLSystemSKUDesc: '',
        CustomerProductID: '',
        CustomerDCCode: '',
        CustomerProductDesc: '',
        TCPLCustomerCode: '',
        TCPLPlantCode: '',
        VendorCode: '',
        errors: {},
        TCPLSystemSKUList: [],
        previousCustomerDCCode: '',
        previousTCPLCustomerCode: '',
        Division: ''
    });
    const { TCPLParentSKU, TCPLParentSKUDesc, TCPLSystemSKUList, TCPLSystemSKU, TCPLSystemSKUDesc, CustomerProductID, CustomerDCCode,
        CustomerProductDesc, TCPLCustomerCode, TCPLPlantCode,VendorCode, errors, previousCustomerDCCode, previousTCPLCustomerCode, Division } = state;

    useEffect(() => {
        if (location.state) {
            const { data } = location.state;
            setState(state => ({
                ...state, TCPLParentSKU: data.TCPLPSKU, TCPLParentSKUDesc: '', TCPLSystemSKU: data.TCPLSSKU,
                TCPLSystemSKUDesc: '', CustomerProductID: data.CustProdID, CustomerDCCode: data.CustDC,
                CustomerProductDesc: data.CustProdDesc, TCPLCustomerCode: data.TCPLCustomer, TCPLPlantCode: data.TCPLPlant,VendorCode: data.VendorCode,
                TCPLParentSKUDesc: data.ParentSKUDescription, TCPLSystemSKUDesc: data.SystemSKUDescription,
                previousCustomerDCCode: data.CustDC, previousTCPLCustomerCode: data.TCPLCustomer, Division: data.Division != undefined ? data.Division : ''
            }))
        }
    }, [location])

    const handleValueChange = (info, e) => {
        // if (e !== "") errors[info] = "";
        if (info === 'TCPLSystemSKU') {
            setState(state => ({ ...state, [info]: e }));
            if (e && TCPLSystemSKUList) {
                TCPLSystemSKUList && TCPLSystemSKUList.map(item => {
                    if (item.SK === e) setState(state => ({ ...state, TCPLSystemSKUDesc: item.SystemSKUDescription, Division: item.Division }))
                })
            }
        } else setState(state => ({ ...state, [info]: e.target.value }));

        if (TCPLParentSKU != "" && TCPLParentSKUDesc != "" && TCPLSystemSKU != "" && TCPLSystemSKUDesc != "" && CustomerProductID != "" &&
            CustomerDCCode != "" && CustomerProductDesc != "" && TCPLCustomerCode != "" && TCPLPlantCode != "" && VendorCode != "") { setSubmitBtn(false); }
        else if ((TCPLParentSKU === "" || TCPLParentSKU === undefined) || (TCPLSystemSKU === "" || TCPLSystemSKU === undefined) ||
            (CustomerProductID === "" || CustomerProductID === undefined) || (CustomerProductDesc === "" || CustomerProductDesc === undefined) ||
            (CustomerDCCode === "" || CustomerDCCode === undefined) || (TCPLCustomerCode === "" || TCPLCustomerCode === undefined) ||
            (TCPLPlantCode === "" || TCPLPlantCode === undefined) || (VendorCode === "" || VendorCode === undefined)) { setSubmitBtn(true); }

        if (window.location.pathname === MASTER_DATA_EDIT) {
            if (info === 'CustomerDCCode') setState(state => ({ ...state, TCPLCustomerCode: '' }))
        }
    }

    const handleBlur = async (info, e) => {
        var Ids, errors = {};
        if (info === 'TCPLParentSKU') { Ids = { SKUParent: e.target.value } }
        else if (info === 'CustomerProductID') { Ids = { CustProductID: { CustomerPayerID: companyName, CustomerProductID: e.target.value } } }
        else if (info === 'CustomerDCCode') { Ids = { DCCodeID: { CustomerPayerID: companyName, DCCode: e.target.value } } }
        else if (info === 'TCPLCustomerCode') { Ids = { TCPLCode: { CustomerPayerID: companyName, CustomerCode: e.target.value } } }

        try {
            const apiData = await API.graphql({ query: productMapping, variables: { Type: "GET_MAPPING", Ids } })
            const { body, statusCode } = apiData.data.productMapping;
            if (statusCode == 200) {
                const { SKUParent, CustProductID, CustomerCode, DCCode } = body;
                if (info === 'TCPLParentSKU' && SKUParent && SKUParent.length > 0) {
                    setState(state => ({ ...state, TCPLParentSKUDesc: SKUParent[0].ParentSKUDescription, TCPLSystemSKUList: SKUParent }))
                } else if (CustProductID && info === 'CustomerProductID') {
                    const { SystemSKUCode, ParentSKUCode } = CustProductID;
                    if (SystemSKUCode != null && ParentSKUCode != null && ParentSKUCode != TCPLParentSKU && SystemSKUCode != TCPLSystemSKU) {
                        errors.CustomerProductID = `${'Already mapped with Parent SKU "' + ParentSKUCode + ' "'}`
                    }
                } else if (info === 'CustomerDCCode' && CustomerCode) {
                    setState(state => ({ ...state, TCPLCustomerCode: CustomerCode }))
                } else if (info === 'TCPLCustomerCode' && DCCode) {
                    if (DCCode != CustomerDCCode) { errors.TCPLCustomerCode = `${'Already mapped with DC Code "' + DCCode + ' " '}` }
                }
                setState(state => ({ ...state, errors }))
            }
        } catch (err) { console.log("error", err); }
    }

    const handleSubmit = async (from) => {
        if (Object.keys(errors).length === 0) {
            var body = {};
            if (from === CREATE || from == UPDATE) {
                body.Map = {
                    Division: Division,
                    ParentSKUCode: TCPLParentSKU,
                    ParentSKUDescription: TCPLParentSKUDesc,
                    SystemSKUCode: TCPLSystemSKU,
                    SystemSKUDescription: TCPLSystemSKUDesc,
                    CustomerPayerID: companyName,
                    DCCode: CustomerDCCode,
                    CustomerCode: TCPLCustomerCode,
                    CustomerProductID: CustomerProductID,
                    PlantCode: TCPLPlantCode,
                    VendorCode: VendorCode,
                    CustomerProductDescription: CustomerProductDesc
                }
                if (from === CREATE) { body.Type = MAPPING }
            }
            if (from === UNMAP) {
                body.UnMap = {
                    ParentSKUCode: TCPLParentSKU,
                    SystemSKUCode: TCPLSystemSKU,
                    CustomerPayerID: companyName,
                    DCCode: CustomerDCCode,
                    CustomerCode: TCPLCustomerCode,
                    CustomerProductID: CustomerProductID,
                }
                body.Type = UN_MAPPING;
            } else if (from === UPDATE && window.location.pathname === MASTER_DATA_EDIT) {
                if (previousCustomerDCCode != CustomerDCCode || previousTCPLCustomerCode != TCPLCustomerCode) {
                    body.UnMap = {
                        ParentSKUCode: TCPLParentSKU,
                        SystemSKUCode: TCPLSystemSKU,
                        CustomerPayerID: companyName,
                        DCCode: previousCustomerDCCode,
                        CustomerCode: previousTCPLCustomerCode,
                        CustomerProductID: CustomerProductID,
                    }
                }
                body.Type = UPDATE_MAPPING;
            }
            try {
                const apiData = await API.graphql({ query: productMapping, variables: body });
                message.success('Product data mapped successfully!');
                history.push(MASTER_DATA_DASHBOARD)
            } catch (error) {
                if (error.errors[0].errorType.includes("ConditionalCheckFailedException")) {
                    message.error('Already existed user data');
                } else message.error('Invalid Data');
            }
        }
    }

    const handleEditSubmit = () => { setEditBtn(!editBtn) }
    const handleCancel = () => { history.push(MASTER_DATA_DASHBOARD) }
    const nameChange = (e) => { setCompanyName(e); }

    const TCPLSystemSKUOptions = useMemo(() => getSystemSKUList(TCPLSystemSKUList), [TCPLSystemSKUList])

    return (
        <Dashboard onCompanyNameChange={(e) => nameChange(e)}>
            <Row>
                <Col lg={{ span: '24' }} className="bgWhite viewDetailsPage borderRadius5rem">
                    <h2 className="heading18Bold margin0">{window.location.pathname != MASTER_DATA_EDIT ? 'Add New' : editBtn ? 'Update' : null} Product Data</h2>
                    <div className="CreateUserForm">
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }} >
                                <p className="customeSideLabel beforeLabelStar">TCPL Parent SKU</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <CustomInput className="beforeStar " value={TCPLParentSKU || undefined} placeholder='Enter TCPL Parent SKU' disabled={window.location.pathname === MASTER_DATA_EDIT}
                                    onChange={(e) => { handleValueChange("TCPLParentSKU", e) }} inputClassname="inputBoxField" errorText={errors.TCPLParentSKU} onBlur={(e) => handleBlur('TCPLParentSKU', e)} />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }} >
                                <p className="customeSideLabel beforeLabelStar">TCPL Parent SKU Desc</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <CustomInput className="beforeStar " value={TCPLParentSKUDesc || undefined} placeholder='TCPL Parent SKU Desc'
                                    onChange={(e) => { handleValueChange("TCPLParentSKUDesc", e) }} inputClassname="inputBoxField" disabled />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }} >
                                <p className="customeSideLabel beforeLabelStar">TCPL System SKU</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <DropDownSelect placeholder='Enter TCPL System SKU' className="beforeStar" value={TCPLSystemSKU || undefined} onChange={(e) => { handleValueChange("TCPLSystemSKU", e) }} disabled={window.location.pathname === MASTER_DATA_EDIT}
                                    selectStyles="selectStyles" options={TCPLSystemSKUOptions} errorText={errors.TCPLSystemSKU} errorMsg="true" />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }} >
                                <p className="customeSideLabel beforeLabelStar">TCPL System SKU Desc</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <CustomInput className="beforeStar " value={TCPLSystemSKUDesc || undefined} placeholder='TCPL System SKU Desc'
                                    onChange={(e) => { handleValueChange("TCPLSystemSKUDesc", e) }} inputClassname="inputBoxField" disabled />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }} >
                                <p className="customeSideLabel beforeLabelStar">Customer Product ID</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <CustomInput className="beforeStar " value={CustomerProductID || undefined} placeholder='Enter Customer Product ID' onBlur={(e) => handleBlur('CustomerProductID', e)}
                                    onChange={(e) => { handleValueChange("CustomerProductID", e) }} inputClassname="inputBoxField" errorText={errors.CustomerProductID} disabled={window.location.pathname === MASTER_DATA_EDIT} />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }} >
                                <p className="customeSideLabel beforeLabelStar">Customer DC Code</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <CustomInput className="beforeStar " value={CustomerDCCode || undefined} placeholder='Enter Customer DC Code' onBlur={(e) => { handleBlur('CustomerDCCode', e) }} errorText={errors.CustomerDCCode}
                                    onChange={(e) => { handleValueChange("CustomerDCCode", e) }} inputClassname="inputBoxField" readOnlyCondition={window.location.pathname === MASTER_DATA_EDIT && !editBtn ? true : false} />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }} >
                                <p className="customeSideLabel beforeLabelStar">Customer Product Desc</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <CustomInput className="beforeStar " value={CustomerProductDesc || undefined} placeholder='Enter Customer Product Desc' readOnlyCondition={window.location.pathname === MASTER_DATA_EDIT && !editBtn ? true : false}
                                    onChange={(e) => { handleValueChange("CustomerProductDesc", e) }} inputClassname="inputBoxField" errorText={errors.CustomerProductDesc} />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }} >
                                <p className="customeSideLabel beforeLabelStar">TCPL Customer Code</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <CustomInput className="beforeStar " value={TCPLCustomerCode || undefined} placeholder='Enter TCPL Customer Code' onBlur={(e) => { handleBlur('TCPLCustomerCode', e) }}
                                    onChange={(e) => { handleValueChange("TCPLCustomerCode", e) }} inputClassname="inputBoxField" errorText={errors.TCPLCustomerCode} readOnlyCondition={window.location.pathname === MASTER_DATA_EDIT && !editBtn ? true : false} />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }} >
                                <p className="customeSideLabel beforeLabelStar">TCPL Plant Code</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <CustomInput className="beforeStar " value={TCPLPlantCode || undefined} placeholder='Enter TCPL Plant Code' readOnlyCondition={window.location.pathname === MASTER_DATA_EDIT && !editBtn ? true : false}
                                    onChange={(e) => { handleValueChange("TCPLPlantCode", e) }} inputClassname="inputBoxField" errorText={errors.TCPLPlantCode} />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                             <Col lg={{ span: '4' }} xs={{ span: '24' }} >
                                 <p className="customeSideLabel beforeLabelStar">Vendor Code</p>
                             </Col>
                             <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                 <CustomInput className="beforeStar " value={VendorCode || undefined} placeholder='Enter Vendor Code' readOnlyCondition={window.location.pathname === MASTER_DATA_EDIT && !editBtn ? true : false}
                                     onChange={(e) => { handleValueChange("VendorCode", e) }} inputClassname="inputBoxField" errorText={errors.VendorCode} />
                             </Col>
                         </Row>
                        <Row className="gap1emTopBottom">
                            {window.location.pathname != MASTER_DATA_EDIT ? <>
                                <Col lg={{ span: '4' }} xs={{ span: '24' }}> </Col>
                                <Col lg={{ span: '7' }} xs={{ span: '24' }} className="displaySpacebetween">
                                    <CustomeButton buttonLabel="Cancel" className="cancelBtn MDCancelBtn" handleSubmit={handleCancel} />
                                    {userDetails.GSI1PK !== SALES_PERSON_KEY
                                        ? <>
                                            <CustomeButton buttonLabel={"Submit"} className="submitBtn" handleSubmit={() => handleSubmit(CREATE)} disabled={submitBtn} />
                                        </> : null}
                                </Col>
                            </> : userDetails.GSI1PK !== SALES_PERSON_KEY ?
                                <>
                                    {editBtn ? <Col lg={{ span: '4' }} xs={{ span: '24' }}> </Col> : null}
                                    <Col lg={{ span: editBtn ? '7' : '11' }} xs={{ span: '24' }} className="displaySpacebetween ">
                                        {!editBtn ? <CustomeButton buttonLabel="Unmap" className="cancelBtn MDCancelBtn" disabled={true} handleSubmit={() => handleSubmit(UNMAP)} /> : null}
                                        <CustomeButton buttonLabel="Cancel" className="cancelBtn MDCancelBtn" handleSubmit={handleCancel} />
                                        {!editBtn ? <CustomeButton buttonLabel={'Edit'} className="submitBtn" handleSubmit={handleEditSubmit} disabled={true} /> : null}
                                        {editBtn ? <CustomeButton buttonLabel={"Update"} className="submitBtn" handleSubmit={() => handleSubmit(UPDATE)} disabled={submitBtn} /> : null}
                                    </Col>
                                </> : <>
                                    <Col lg={{ span: '4' }} xs={{ span: '24' }}> </Col>
                                    <Col lg={{ span: editBtn ? '7' : '11' }} xs={{ span: '24' }} className="displaySpacebetween">
                                        <CustomeButton buttonLabel="Cancel" className="cancelBtn MDCancelBtn" handleSubmit={handleCancel} />
                                    </Col>
                                </>}
                        </Row>
                    </div>
                </Col>
            </Row>
        </Dashboard>
    )
}

export default CreateMasterData;