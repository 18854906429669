import React, { useState } from 'react';
import { message } from 'antd';
import CustomSearchBar from '../../../utils/components/customeInputFields/searchField';
import { API } from 'aws-amplify';
import { querySearchAnythingByID } from '../../../graphql/queries';
import { useHistory } from 'react-router-dom';
import { ORDERS_PODATA } from '../../../utils/routingUrl';
import { getItems } from '../../../utils/functions';
const ORDER = 'Order';

const GlobalSearch = ({ }) => {
    const [searchValue, setSearchValue] = useState("");
    const [nextToken, setNextToken] = useState(undefined);
    const history = useHistory();

    const handleValueChange = (e) => { setSearchValue(e.target.value); }

    // fetching Data while click on search
    const onSearch = async () => {
        setSearchValue(searchValue);
        const apiData = await API.graphql({ query: querySearchAnythingByID, variables: { GSI2: searchValue, first: 10, after: nextToken } });
        var data = apiData.data.querySearchAnythingByID.items;
        if (data.length) {
            // if( data.length && data[0].SK.includes("Invoice"))  history.push({pathname: `/orders/${data[0].SK}`, state:{SK:"InvoiceItem#", PK:data[0].PK, wholeData:data[0]}});
            // else history.push({pathname: `/orders/${data[0].SK}`, state:{SK:"Item#", PK:data[0].PK +"#"+ data[0].SK, wholeData:data[0] }});
            if (data[0].Type === ORDER) { history.push({ pathname: ORDERS_PODATA, state: { PK: data[0].PK, SK: data[0].SK } }) }
            else history.push({ pathname: `/orders/${data[0].SK}`, state: { SK: "InvoiceItem#" + getItems(data[0].SK, 1), PK: data[0].PK, wholeData: data[0] } });
        } else message.warning("No Data Found")
    }

    return (<CustomSearchBar placeholder="Search By PO / Invoice ID" className={"width300"} value={searchValue} disable={false}
        inputClassname="width300 Search-main" onChange={(e) => { handleValueChange(e) }} onSearch={onSearch} />)
}

export default GlobalSearch;