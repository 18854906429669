import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Login from './components/authentication/login';
import OrdersDashboard from './components/orders';
import OrdersActivity from './components/orders/features/orderActivity';
import SearchedData from './components/searchedData';
import HomePage from './components/home'
import {
  COMAPNIES_DASHBOARD, DASHBOARD, HOME, PRODUCT_DATA_DASHBOARD, ORDERS_ACTIVITY, ORDERS_DASHBOARD, ORDERS_PODATA, ORDERS_PODETAILS,
  PAGE_BY_SEARCH, USERS_CREATE, USERS_DASHBOARD, USERS_EDIT, PRODUCT_DATA_CREATE, CUSTOMER_DATA_CREATE, CUSTOMER_DATA_DASHBOARD, PRODUCT_DATA_EDIT, COMAPNIES_CREATE, MASTER_DATA_DASHBOARD, MASTER_DATA_CREATE, MASTER_DATA_EDIT, SO_MAIL_USERS_CREATE, ERROR_MAIL_USERS_CREATE
} from './utils/routingUrl';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import POData from './components/orders/features/POData';
import POInnerScreens from './components/orders/features/PoInnerScreens';
import UsersDashboard from './components/users';
import CompaniesDashboard from './components/companies';
import CreateUser from './components/users/features/createEditUser';
import ProductDataDashboard from './components/productData';
import CreateProductData from './components/productData/features/createEditProduct';
import CreateCustomerData from './components/customerData/features/createEditProduct';
import CustomerDataDashboard from './components/customerData';
import CreateCompanies from './components/companies/features/createEditCompanies';
import MasterDataDashboard from './components/masterData';
import CreateMasterData from './components/masterData/features/CreateEdit';
import CreateSOMail from './components/users/features/createEditSOMail';
Amplify.configure(awsconfig);
require('typeface-muli');

function App() {
  return (
    <Switch>

      {/* Orders Routing */}
      <Route exact path={ORDERS_PODATA} render={(props) => requireAuth(<POData {...props} />)} />
      <Route exact path={ORDERS_PODETAILS} render={(props) => requireAuth(<POInnerScreens {...props} />)} />
      <Route exact path={ORDERS_ACTIVITY} render={(props) => requireAuth(<OrdersActivity {...props} />)} />
      <Route exact path={PAGE_BY_SEARCH} render={(props) => requireAuth(<SearchedData {...props} />)} />
      <Route exact path={ORDERS_DASHBOARD} render={(props) => requireAuth(<OrdersDashboard {...props} />)} />

      {/* Users Routing */}
      <Route exact path={USERS_EDIT} render={(props) => requireAuth(<CreateUser {...props} />)} />
      <Route exact path={USERS_CREATE} render={(props) => requireAuth(<CreateUser {...props} />)} />
      <Route exact path={SO_MAIL_USERS_CREATE} render={(props) => requireAuth(<CreateSOMail {...props} />)} />
      <Route exact path={ERROR_MAIL_USERS_CREATE} render={(props) => requireAuth(<CreateSOMail {...props} />)} />
      <Route exact path={USERS_DASHBOARD} render={(props) => requireAuth(<UsersDashboard {...props} />)} />

      {/* Comapnies Routing */}
      <Route exact path={COMAPNIES_DASHBOARD} render={(props) => requireAuth(<CompaniesDashboard {...props} />)} />
      <Route exact path={COMAPNIES_CREATE} render={(props) => requireAuth(<CreateCompanies {...props} />)} />

      {/* master data Routing */}
      <Route exact path={MASTER_DATA_DASHBOARD} render={(props) => requireAuth(<MasterDataDashboard {...props} />)} />
      <Route exact path={MASTER_DATA_CREATE} render={(props) => requireAuth(<CreateMasterData {...props} />)} />
      <Route exact path={MASTER_DATA_EDIT} render={(props) => requireAuth(<CreateMasterData {...props} />)} />

      {/* product data Routing*/}
      <Route exact path={PRODUCT_DATA_DASHBOARD} render={(props) => requireAuth(<ProductDataDashboard {...props} />)} />
      <Route exact path={PRODUCT_DATA_CREATE} render={(props) => requireAuth(<CreateProductData {...props} />)} />
      <Route exact path={PRODUCT_DATA_EDIT} render={(props) => requireAuth(<CreateProductData {...props} />)} />

      {/* customer data Routing */}
      <Route exact path={CUSTOMER_DATA_DASHBOARD} render={(props) => requireAuth(<CustomerDataDashboard {...props} />)} />
      <Route exact path={CUSTOMER_DATA_CREATE} render={(props) => requireAuth(<CreateCustomerData {...props} />)} />

      <Route exact path={DASHBOARD} render={(props) => requireDashboardAuth(<HomePage {...props} />)} />
      <Route exact path={HOME} render={(props) => redirectAuth(props)} />
    </Switch>
  );
}

export default App;

// For No File Found Navigate to Home
function requireAuth(Component) {
  const authenticated = JSON.parse(sessionStorage.getItem('isLogged'))
  const companyNameSelected = sessionStorage.getItem('companyName')
  if (authenticated && companyNameSelected) return Component
  else return <Redirect to={HOME} />
}

// For No File Found Navigate to Home
function requireDashboardAuth(Component) {
  const authenticated = JSON.parse(sessionStorage.getItem('isLogged'))
  const companyNameSelected = sessionStorage.getItem('companyName')
  if (authenticated && (companyNameSelected === null)) return Component;
  else if (authenticated && companyNameSelected) return <Redirect to={ORDERS_DASHBOARD} />
  else return <Redirect to={HOME} />
}

// For Login Authentication 
function redirectAuth(props) {
  const authenticated = JSON.parse(sessionStorage.getItem('isLogged'))
  const companyNameSelected = sessionStorage.getItem('companyName')
  if (!authenticated) return <Login {...props} />
  else if (authenticated && (companyNameSelected == null)) return <Redirect to={DASHBOARD} />
  else if (authenticated && companyNameSelected) return <Redirect to={ORDERS_DASHBOARD} />
}